<template>
  <v-col cols="12">
    <Loader
      v-if="loading"
      :key="generateUniqueId()"
      :loader-state="loading"
    ></Loader>

    <v-card>
      <v-card-title>Traductions</v-card-title>

      <v-row class="mb-5">
        <v-col
          class="tags-search ml-5"
          cols="2"
          sm="2"
        >
          <vue-tags-input
            v-model="tag"
            :tags="tags"
            placeholder="Recherche"
            @tags-changed="newTags => {
              tags = newTags
              search()
            }"
          />
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-btn
            class="mt-1"
            small
            color="info"
            @click="triggerSearch()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiDatabaseSearchOutline }}
            </v-icon>
            <i class="fas fa-search"></i>
            Rechercher
          </v-btn>
          <v-btn
            small
            color="info"
            class="ml-2 mt-1"
            @click="reset()"
          >
            <v-icon
              small
              class="mr-1"
            >
              {{ icons.mdiBackupRestore }}
            </v-icon>
            Réinitialisation
          </v-btn>
        </v-col>
      </v-row>

      <div class="clear"></div>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Catégorie
              </th>
              <th class="text-center text-uppercase">
                Identifiant
              </th>
              <th class="text-center text-uppercase">
                Traduction
              </th>
              <th class="text-center text-uppercase">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="translation in translations"
              :key="translation.id"
            >
              <td class="font-weight-bold">
                {{ resolveDomain(translation.key) }}
              </td>
              <td class="text-center">
                {{ translation.key }}
              </td>
              <td class="text-center">
                {{ translation.translations }}
              </td>
              <td class="text-center">
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="warning"
                      fab
                      dark
                      x-small
                      v-bind="attrs"
                      :to="{ name: 'translationDetails', params: { translationKey: translation.key }}"
                    >
                      <v-icon dark>
                        {{ icons.mdiPen }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Consulter</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!translations.length">
            <tr>
              <td>
                <span v-if="loading === false">Aucun éléments.</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-col>
</template>

<script>
import { mdiPen, mdiBackupRestore, mdiDatabaseSearchOutline } from '@mdi/js'
import Vue from 'vue'
import config from '../../config'
import VueTagsInput from '@johmun/vue-tags-input'
import Loader from '@/components/Common/Loader'

export default {
  components: {
    VueTagsInput,
    Loader
  },
  data: () => ({
    loading: false,
    translations: [],
    nextPage: 1,
    tag: '',
    tags: [],
    icons: {
      mdiPen,
      mdiBackupRestore,
      mdiDatabaseSearchOutline
    }
  }),
  computed: {
    isHandlingTranslationScroll () {
      return this.$store.getters.isHandlingTranslationScroll
    }
  },
  created () {
    this.getTranslations()

    window.removeEventListener('scroll', this.handleScroll)

    setTimeout(() => {
      window.addEventListener('scroll', this.handleScroll)
    }, 1000)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const wrapperOffsetHeight = document.querySelector('.v-application--wrap').offsetHeight
      const bottomOfWindow = (document.documentElement.scrollTop + window.innerHeight) === wrapperOffsetHeight

      if (bottomOfWindow && !this.isHandlingTranslationScroll) {
        this.getTranslations()
      }
    },
    getTranslations () {
      this.loading = true

      const params = {
        page: this.nextPage
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      this.$store.commit('setHandlingTranslationScroll', true)

      Vue.prototype.$http
        .get(config.apiUrl + '/api/translations', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            const translations = response.data

            if (translations && translations.length) {
              this.translations.push(...translations)

              this.nextPage = this.nextPage + 1
            }
          }

          this.loading = false
          this.$store.commit('setHandlingTranslationScroll', false)
        })
        .catch(() => {
          this.loading = false
          this.$store.commit('setHandlingTranslationScroll', false)
        })
    },
    search () {
      this.loading = true

      const params = {
        page: 1
      }

      const terms = []

      for (let i = 0; i < this.tags.length; i++) {
        terms.push(this.tags[i].text)
      }

      if (terms.length) {
        params.terms = terms.join(',')
      }

      Vue.prototype.$http
        .get(config.apiUrl + '/api/translations', {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.getToken
          },
          params: params
        })
        .then(response => {
          if (response.status === 200) {
            this.translations = response.data

            this.nextPage = 2
          }

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    reset () {
      this.tag = ''
      this.tags = []

      this.search()
    },
    triggerSearch () {
      document.getElementsByClassName('ti-new-tag-input')[0].blur()
    },
    resolveDomain (domain) {
      const data = domain.split('.')

      return data[0]
    },
    truncate (name) {
      if (name && name.length > 100) {
        return name.substring(0, 100) + '...'
      }

      return name
    }
  }
}
</script>
